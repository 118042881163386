<template>
  <div class="df jc-sb ai-c w-100p">
    <div style="width: 50%; margin-right: 8px">
      <div class="m-b-9">
        <span>C</span>
      </div>
      <IqSelectDate
        v-model="time_from"
        inputmode="none"
        format="dd.MM.yyyy"
        placeholder="Выберите дату"
        class="w-100p"
        @onChange="handleChoseDate"
      />
    </div>

    <div style="width: 50%; margin-left: 8px">
      <div class="m-b-9">
        <span>По</span>
      </div>
      <IqSelectDate
        v-model="time_to"
        inputmode="none"
        format="dd.MM.yyyy"
        placeholder="Выберите дату"
        class="w-100p"
        :options="pickerOptionsDateTo"
        @onChange="handleChoseDate"
      />
    </div>
  </div>
</template>

<script>
import {
  DATE_FORMAT,
  DATE_FORMAT_FULL,
  DATE_FORMAT_RU,
  MILLISECOND_IN_DAY,
} from '@/constants/date'
import { getTime, now, pickerOptions } from '@/core'
import IqSelectDate from '@/views/ui/components/select/IqSelectDate'

export default {
  components: { IqSelectDate },

  props: {
    chosenEntities: {
      type: Array,
      default: () => [],
    },
    titleSection: {
      type: String,
      default: 'test title',
    },
    type: {
      type: String,
    },
  },

  data() {
    return {
      pickerOptionsDateTo: {
        disabledDate: time => {
          if (this.time_from) {
            return (
              this.time_from > time.getTime() ||
              time.getTime() > this.time_from + MILLISECOND_IN_DAY
            )
          }
        },
      },
      time_to: '',
      time_from: '',
      isSearch: false,
      statuses: [],
      filterEntries: [],
    }
  },
  computed: {
    chosenStatuses() {
      const chosen = this.entries.filter(
        item => this.statuses.indexOf(item.value) > -1,
      )

      this.$emit('handleChangeFilter', chosen)

      return chosen
    },
  },
  watch: {
    time_from(value) {
      if (value > this.time_to) {
        this.time_to = ''
      }
    },
  },
  mounted() {
    this.chosenEntities.forEach(select => {
      if (select.time_from) {
        this.time_from = select.time_from
      }
      if (select.time_to) {
        this.time_to = select.time_to
      }
    })
  },
  methods: {
    handleChoseDate() {
      let arr = []

      if (this.time_from) {
        arr.push({
          value: 'time_from',
          time_from: this.time_from,
          label: 'c ' + getTime(this.time_from, DATE_FORMAT_RU),
        })
      }
      if (this.time_to) {
        arr.push({
          value: 'time_to',
          time_to: this.time_to,
          label: 'по ' + getTime(this.time_to, DATE_FORMAT_RU),
        })
      }
      this.$emit('handleChangeFilter', arr, this.type)
    },
    handleCheckDateTo() {
      if (this.date_from > this.date_to) {
        this.date_to = null
      }
    },
    handleCheckDateFrom() {
      if (this.date_from > this.date_to) {
        this.date_to = null
      }
      if (
        getTime(this.date_from, DATE_FORMAT) === getTime(now(), DATE_FORMAT)
      ) {
        this.time_from = null
      }
    },
    handleDeleteFilter(id) {
      if (id === null) {
        this.statuses = []
      }
      if (id) {
        this.statuses = this.statuses.filter(item => item !== id)
      }
    },
    openModal(id) {
      this.$emit('open', id)
    },
    handleFilterEntities() {
      if (this.search.length > 0) {
        this.isSearch = true
        this.filterEntries = this.entries.filter(item =>
          item.label.includes(this.search),
        )
      }
    },
    handleCleanSearch() {
      this.isSearch = false

      this.filterEntries = []
      this.search = ''
    },
  },
}
</script>

<style lang="sass" scoped></style>
